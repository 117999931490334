import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{staticStyle:{"height":"1200px"},attrs:{"width":"800px","value":_vm.isActive},on:{"input":function (val) { return _vm.$emit('update:is-active', val); }}},[_c(VCard,{staticClass:"transition-fast-in-fast-out",staticStyle:{"height":"100%"}},[_c(VCardTitle,[_c('span',{staticClass:"text-big cursor-pointer"},[_vm._v(" Complaint : "+_vm._s(_vm.Order.Id)+" ")]),_c(VSpacer)],1),_c(VDataTable,{attrs:{"height":"230","fixed-header":"","headers":_vm.tableColumns,"items":_vm.Order.Complains,"item-key":"Id","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item.CreationTime",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',{staticClass:"d-block text--primary font-weight-semibold text-truncate"},[_vm._v(_vm._s(_vm.filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')))])])])]}}],null,true)},[_vm._v(" calculate-widths: true ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }