import { GetForOwner as getComplains } from '@core/api/Complain'
import { GetForOwner } from '@core/api/Order'
import { DateTimeFormatter, Instant, LocalDateTime } from '@js-joda/core'
import { mdiCart, mdiCurrencyUsd } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
export default function useOrderList() {
  // Table Handlers
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Restaurant Name', value: 'RestaurantName' },
    { text: 'Branch Name', value: 'BranchName' },
    { text: 'Customer Name', value: 'Name' },
    { text: 'Phone', value: 'PhoneNumber' },
    { text: 'Creation Time', value: 'CreationTime' },
    { text: 'Agent', value: 'FullName', width: '250' },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Complain', value: 'Complain', sortable: false },
  ]

  const OrderListTable = ref([])
  const ComplainList = ref([])
  const expanded = ref([])
  const searchQuery = ref('')
  const searchDateFrom = ref(new Date())
  const searchDateTo = ref(new Date())
  const statusFilter = ref(null)
  const orderSourceFilter = ref(null)
  const restaurntFilter = ref(null)
  const branchFilter = ref(null)
  const totalOrderListTable = ref(0)
  const OrderTotalLocal = ref([])
  let canceltotal = ref(0)
  let cancelCount = ref(0)
  let JustComplains = ref(false)
  searchDateFrom.value.setHours(0, 0, 0, 0)
  searchDateTo.value.setHours(23, 59, 59, 999)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  let total = 0
  const ResId = JSON.parse(localStorage.getItem('userData')).RestaurantId
  const loading = ref(false)
  const CallPrice = item => {
    total +=
      item.OrderTransations.reduce((prev, cur) => {
        return prev + parseFloat(cur.TotalPrice) * cur.Qty
      }, 0) +
      item.PromoCodeAmount -
      item.OrderTransations.reduce((prev, cur) => {
        return prev + parseFloat(cur.TotalPrice) * cur.Qty
      }, 0) *
        item.PromoCodePercentage
  }
  const fetchOrders = () => {
    GetForOwner({
      RestaurantId: ResId,
      BranchId: branchFilter.value,
      Any: searchQuery.value,
      Status: statusFilter.value,
      OrderSource: orderSourceFilter.value,
      DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
      DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
        DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
      ),
      JustComplains: JustComplains.value,
    })
      .then(response => {
        // handle success
        total = 0
        canceltotal.value = 0
        cancelCount = ref(0)
        response.Items.forEach((i, index) => {
          if (i.Status != 'cancelled') {
            CallPrice(i)
            //cancelledTotal.value = cancelledTotal.value + i.TotalAmount
            //cancelledCount.value = cancelledCount.value + 1
            cancelCount.value = cancelCount.value + 1
          } else {
            canceltotal.value = canceltotal.value + 1
          }
          i.OrderTransations.forEach((item, index) => {
            if (item.Addon != '') {
              item.Addon = JSON.parse(item.Addon)
              item.Addon2 = item.Addon
            }
          })
        })
        OrderListTable.value = response.Items
        totalOrderListTable.value = response.length
        response.Totals.push({ title: 'Total Amount', total: total })
        OrderTotalLocal.value = response.Totals.reverse()
        console.log('totaltotal', total)

        //cancelTotal.value = cancelledTotal.value
        // cancelCount.value = cancelledCount.value
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
    if (branchFilter.value) {
      getComplains({
        DateFrom: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateFrom.value)).format(
          DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
        ),
        DateTo: LocalDateTime.ofInstant(Instant.ofEpochMilli(searchDateTo.value)).format(
          DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm'),
        ),
        BranchId: branchFilter.value,
      }).then(response => {
        console.log('res', response)
        ComplainList.value = response
      })
    }
  }

  watch(
    [
      options,
      searchQuery,
      branchFilter,
      statusFilter,
      searchDateTo,
      searchDateFrom,
      restaurntFilter,
      orderSourceFilter,
      JustComplains,
    ],
    () => {
      loading.value = true
      fetchOrders()
    },
  )
  const resolveTotalIcon = total => {
    if (total === 'Count') return { icon: mdiCart, color: 'primary' }
    if (total === 'Total Amount') return { icon: mdiCurrencyUsd, color: 'success' }

    return { icon: mdiCart, color: 'primary' }
  }
  return {
    ResId,
    total,
    options,
    loading,
    expanded,
    canceltotal,
    cancelCount,
    searchQuery,
    ComplainList,
    tableColumns,
    statusFilter,
    branchFilter,
    searchDateTo,
    JustComplains,
    searchDateFrom,
    OrderListTable,
    OrderTotalLocal,
    restaurntFilter,
    orderSourceFilter,
    totalOrderListTable,
    CallPrice,
    fetchOrders,
    resolveTotalIcon,
  }
}
