<template>
  <v-dialog @click:outside="resetComplainData()" style="height: 1600px" width="1000px" :value="isComplainBranchActive" @input="val => $emit('update:update:is-complain-branch-active', val)">
  <v-card class="transition-fast-in-fast-out" style="height: 100%">
  <v-card-title>
          <!-- <span class="text-big cursor-pointer"> Complaint : {{ Order.Id }} </span> -->
          <v-spacer></v-spacer>
        </v-card-title>
    <v-data-table
    height = "350"
    fixed-header
    :headers="tableColumns"
    :items="Order"
    item-key="Id"
    hide-default-footer
    :items-per-page="-1"

    >
    <!-- calculate-widths: true -->
      <!-- CreationTime -->
      <template #[`item.CreationTime`]="{ item }">
        <div class="d-flex align-center">
          <div class="d-flex flex-column ms-3">
            <span class="d-block text--primary font-weight-semibold text-truncate">{{
              filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
            }}</span>
          </div>
        </div>
      </template>
    </v-data-table>
    </v-card>
     <v-divider class="mt-6 mb-6"></v-divider>
  </v-dialog>
</template>

<script>
import { parseTime } from '@core/utils/filter'
import { required } from '@core/utils/validation'
import { mdiCheckboxMarkedCircle, mdiCloseCircle, mdiPencil } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

export default {

  model: {
    prop: 'isComplainBranchActive',
    event: 'update:is-complain-branch-active',
  },
  props: {
    isComplainBranchActive: {
      type: Boolean,
      required: true,
    },
    Order: {
      type: Array,
      required: true,
    },

  },
    watch: {
      Order(val) {
        this.ComplainData.OrderId = val.Id
      },
    },
  setup(props, { emit }) {
    const check = ref (false)
    const isSelected = ref(false)
    const blankData = {
      Id: undefined,
      ComplainCategory: '',
      Comment: '',
      OrderId: undefined,
      BranshId: undefined,

    }
    const tableColumns = [
      { text: 'id', value: 'Id', sortable: false },
      { text: 'Complain Category', value: 'ComplainCategory', sortable: false },
      { text: 'Comment', value: 'Comment', sortable: false },
      { text: 'Creation Time', value: 'CreationTime', sortable: false },
    ]
    const valid = ref(false)
    const rowclick = ref(0)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const ComplainData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetComplainData = () => {
      ComplainData.value = JSON.parse(JSON.stringify(blankData))
      resetForm()
      isSelected.value = false
      emit('update:is-complain-branch-active', false)
    }
    const close = () => {
      resetComplainData()
    }


     const isreplied = (item) => {
      if (item == null || item == '')
      return false
      else { return true }
     }
     watch(
    [
      ComplainData,
    ],
    () => {
      ComplainData.value
    },
  )



    return {
      check,
      isSelected,
      tableColumns,
      ComplainData,
      close,
      validate,
      resetComplainData,
      rowclick,
      isreplied,
      form,
      validators: { required },
      valid,
      filter: { parseTime },
      icons: {
        mdiPencil,
        mdiCheckboxMarkedCircle,
        mdiCloseCircle,
      },
    }
  },
}
</script>
