<template>
  <v-row>
    <v-col cols="12">
    <complain-dialog
      @refetch-data="fetchOrders"
      v-model="isActive"
      :Order="complainTemp"
    ></complain-dialog>
    <complain-branch-dialog
      @refetch-data="fetchOrders"
      v-model="isComplainBranchActive"
      :Order="ComplainList"
    ></complain-branch-dialog>
    <v-row>
      <v-switch
      v-model="JustComplains"
      label="Complains Orders"
    ></v-switch>
    </v-row>

      <v-row class="mb-5">
        <v-col v-for="(total, i) in OrderTotalLocal" :key="i" cols="12" sm="6" md="3">
          <v-card>
            <v-card-text class="d-flex align-center justify-space-between pa-4">
              <div>
                <h2 class="font-weight-semibold mb-1" v-if="total.title =='Count'">
                  {{ total.total - cancelCount  }}
                </h2>
                <h2 class="font-weight-semibold mb-1" v-else>
                  {{ (total.total).toFixed(2) }}
                </h2>
                <span>{{ total.title }}</span>
              </div>
              <v-avatar
                :color="resolveTotalIcon(total.title).color"
                :class="`v-avatar-light-bg ${resolveTotalIcon(total.title).color}--text`"
              >
                <v-icon size="25" :color="resolveTotalIcon(total.title).color" class="rounded-0">
                  {{ resolveTotalIcon(total.title).icon }}
                </v-icon>
              </v-avatar>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Main Card -->
      <app-card-actions @refresh="fetchOrders">

        <template slot="title">
        <v-badge v-bind:color="ComplainList.length > 0 ? 'error' : 'secondary'" bordered overlap
        :value="ComplainList.length"
          :content="ComplainList.length"
        >
          <v-btn
             icon
             v-bind:color="0 > 0 ? 'error' : 'secondary'"
             @click.stop="isComplainBranchActive = !isComplainBranchActive"
           >
             <v-icon small>
               {{ icons.mdiCommentFlash }}
             </v-icon>
           </v-btn>
         </v-badge>  Orders List </template>
        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-5">
            <select-bransh class="mb-6" v-model="branchFilter" :RestaurantId="ResId" />
            <v-datetime-picker label="Date From" v-model="searchDateFrom">
              <template slot="dateIcon">
                <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </template></v-datetime-picker
            >
            <v-datetime-picker label="Date To" v-model="searchDateTo">
              <template slot="dateIcon">
                <v-icon>{{ icons.mdiCalendarRange }}</v-icon>
              </template>
              <template slot="timeIcon">
                <v-icon>{{ icons.mdiClockOutline }}</v-icon>
              </template></v-datetime-picker
            >
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search "
            ></v-text-field>

            <v-select
              v-model="orderSourceFilter"
              label="Order Source"
              :items="[
                { Id: 1, Name: 'CallCenter' },
                { Id: 2, Name: 'RestaurantApp' },
                { Id: 3, Name: 'Talabat' },
                { Id: 4, Name: 'Careem' },
                { Id: 5, Name: 'Other' },
              ]"
              item-text="Name"
              item-value="Name"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['orders']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </div>
        </v-card-text>

        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="OrderListTable"
          item-key="Id"
          :items-per-page="10"
          :options.sync="options"
          :expanded.sync="expanded"
          show-expand
          :loading="loading"
          :sort-desc="[true]"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- CreationTime -->
          <template #[`item.CreationTime`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{
                  filter.parseTime(item.CreationTime, '{y}-{m}-{d} {h}:{i}')
                }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{
                $store.state.status['orders'].find(obj => {
                  return obj.value == item.Status
                }).title
              }}
            </v-chip>
          </template>
          <!-- Push -->
          <template #[`item.Push`]="{ item }">
            <v-btn v-if="item.Status == 'pinding'" color="info" small @click="PushNotification(item.BranchId)">Push</v-btn>
            </template>
          <!-- Action -->

          <template #[`item.Action`]="{ item }">
            <div class="demo-space-x">
              <vc-change-status
                v-if="item.Status == 'cancelled'"
                @refetch-data="fetchOrders"
                :ObjectId="item.Id"
                TableName="orders"
                Status="pinding"
                :WithDialog="true"
              />
      <div v-if="item.Status == 'cancelrequest'">
                    <v-row justify="space-around">
                    <v-col cols="auto">
                      <v-dialog
                        transition="dialog-top-transition"
                        max-width="600"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                          >Cancel Request</v-btn>
                        </template>
                        <template v-slot:default="dialog">
                          <v-card>
                            <v-toolbar
                              color="primary"
                              dark
                            >Order Cancellation Request</v-toolbar>
                            <v-card-text>
                              <div class="pa-12">
                              <p class="my-1">Name: {{ item.Name }}</p>
                              <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                              <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                              </div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <div class="justify-start">
                              <vc-change-status
                              @refetch-data="dialogFitch"
                              :ObjectId="item.Id"
                              TableName="orders"
                              Status="pinding"
                              :WithDialog="false"
                            />
                              </div>
                              <div class="justify-end">
                            <vc-change-status
                              @refetch-data="dialogFitch"
                              :ObjectId="item.Id"
                              TableName="orders"
                              Status="cancelled"
                              :WithDialog="false"
                            />
                              </div>
                            </v-card-actions>
                          </v-card>
                        </template>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  </div>
            </div>
          </template>
               <!-- complain -->
      <template #[`item.Complain`]="{ item }">
        <v-badge
          v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
          bordered
          overlap
          :value="item.Complains.length - item.ComplainsReplied.length"
          :content="item.Complains.length - item.ComplainsReplied.length"
        >
          <v-btn
            icon
            v-bind:color="item.Complains.length > 0 ? 'error' : 'secondary'"
            @click.stop=";(isActive = !isActive), (complainTemp = { ...item })"
          >
            <v-icon small>
              {{ icons.mdiCommentFlash }}
            </v-icon>
          </v-btn>
        </v-badge>
      </template>
          <!-- expandad data -->
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length / 2">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="6" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Order Items</h3></span>
                    </v-col>
                    <v-col lg="6" md="8" sm="8" cols="8"> </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>

                <v-row v-for="(orderTransation, i) in item.OrderTransations" :key="i">
                  <v-col lg="7" cols="7"
                    >{{ orderTransation.Name }} ({{orderTransation.TotalPrice}}$)
                    <br />
                     <div >
                    <span v-for="(Addon, i) in orderTransation.Addon2" :key="i"
                      >Addon : {{ Addon.Name }} - ({{ Addon.Price }})<br
                    /></span>
                    </div>
                    <span
                      ></span>
                    <span v-if="orderTransation.Description != ''"
                      >Note : {{ orderTransation.Description }} <br
                    /></span>
                  </v-col>
                  <v-col lg="2" cols="2">
                    {{ orderTransation.Qty }}
                  </v-col>
                  <v-col>
                    {{ (parseFloat(orderTransation.TotalPrice) * orderTransation.Qty).toFixed(3) }}
                  </v-col>
                </v-row>
                <v-divider class="mt-1 mb-1"></v-divider>
                <v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Subtotal</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)

                        .toFixed(3)
                      }}
                      JOD
                    </span>

                  </v-col></v-row
                >
                <div v-if="item.OrderPromos !=''">
                <v-divider class="mt-1 mb-1"></v-divider>
                 <v-row v-if="item.PromoCodePercentage >0" >
                  <v-col lg="7" cols="7">
                    <p class="mb-1">PromoCodePercentage:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{ (item.PromoCodePercentage)*100 }}%</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p class="mb-1">{{
                    (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        ).toFixed(3) }}
                        </p>
                  </v-col>
                </v-row>
                <v-row v-if="item.PromoCodeAmount != 0">
                  <v-col lg="9" cols="9">
                    <p  class="mb-1">PromoCodeAmount:</p>
                  </v-col>
                  <v-col lg="2" cols="2">
                    <p  class="mb-1">{{ item.PromoCodeAmount }}</p>
                  </v-col>
                </v-row>
<v-row>
                  <v-col lg="6" md="4" sm="4" cols="4">
                    <span class="justify-end text-lg-h6 ms-1">Total</span>
                  </v-col>
                  <v-col lg="6" md="8" sm="8" cols="8">
                    <span class="d-flex justify-end mb-6">
                      {{
                        (((item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        )+item.PromoCodeAmount)-
                        (item.OrderTransations.reduce((prev, cur) => {
                          return prev + parseFloat(cur.TotalPrice) * cur.Qty
                        }, 0)
                        * item.PromoCodePercentage
                        )).toFixed(3)
                      }}
                      JOD
                    </span>

                  </v-col></v-row
                >
                </div>
<v-divider class="mt-1 mb-1"></v-divider>
<div v-for="i in item.OrderPromos">
                 <p  class="mb-1">
                  Promo: {{ i.Name }}
                 </p>
                 </div>
                 <v-divider class="mt-1 mb-1"></v-divider>
                <p v-if="item.Description != null" class="mb-1">Description: {{ item.Description }}</p>

                <p class="mb-1">DeliveryPrice: {{ item.DeliveryPrice.toFixed(3) }} JD</p>
                <p class="mb-1">TotalAmount: {{ item.TotalAmount.toFixed(3) }} JD</p>
              </v-card>
            </td>
            <td :colspan="headers.length / 2">
              <v-row>
                     <v-col lg="10" md="4" sm="4" cols="10">
                     <span class="d-flex justify-end align-center  mb-6">
                      <print-component Type="Order" :Data="item" />
                      </span>
                    </v-col>
                  </v-row>
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col lg="12" md="4" sm="4" cols="4">
                      <span class="d-flex justify-start"><h3>Customer & Address</h3></span>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-divider class="mt-1 mb-1"></v-divider>
                <p class="my-1">Name: {{ item.Name }}</p>
                <p class="mb-1">Payment Type: {{ item.PaymentType }}</p>
                <p class="mb-1">Order Type: {{ item.OrderType }}</p>
                <p v-if="item.ScheduleTimeOrder > '2020-01-01 02:00:00.0000000'" class="mb-1">
                  Schedule Time Order: {{ filter.parseTime(item.ScheduleTimeOrder, '{y}-{m}-{d} {h}:{i}') }}
                </p>
                <p class="mb-1">Delivery Type: {{ item.DeliveryType }}</p>
                <p v-if="item.DeliveryCompany != null" class="mb-1">Delivery Company: {{ item.DeliveryCompany }}</p>
                <p class="mb-1">Order Source: {{ item.OrderSource }}</p>
                <p v-if="item.OrderSourceRefNumber != null" class="mb-1">
                  Order Source Ref Number: {{ item.OrderSourceRefNumber }}
                </p>
                <p v-if="item.City != null" class="mb-1">City: {{ item.City }}</p>
                <p v-if="item.BranshAddress != null" class="mb-1">Bransh Address: {{ item.BranshAddress }}</p>
                <p v-if="item.AddressDetail != null" class="mb-1">Address Details: {{ item.AddressDetail }}</p>
                <p v-if="item.BuildingNo != null" class="mb-1">Building No: {{ item.BuildingNo }}</p>
                <p v-if="item.StreetNo != null" class="mb-1">Street Name: {{ item.StreetNo }}</p>
              </v-card>
              <br />
                <v-col class="d-flex justify-end" lg="6" md="8" sm="8" cols="8">
                  <vc-change-status
                  v-if="item.Status != 'cancelrequest' && item.Status != 'cancelled' "
                   @refetch-data="fetchOrders()"
                    :ObjectId="item.Id"
                    TableName="orders"
                    Status="cancelled"
                    :WithDialog="true"
                  />

                </v-col>
            </td>
          </template>
        </v-data-table>
      </app-card-actions>
    </v-col></v-row
  >
</template>

<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import DrawerPrint from '@/components/vc-print/DrawerPrint.vue'
import PrintComponent from '@/components/vc-print/PrintComponent.vue'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import SelectBransh from '@/views/Branch/SelectBransh.vue'
import { parseTime } from '@core/utils/filter'
import { mdiCalendarRange, mdiClockOutline, mdiCommentFlash, mdiDeleteOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ComplainBranchDialog from './ComplainBranchDialog.vue'
import ComplainDialog from './ComplainDialog.vue'
import useOrderList from './useOrderList'

// import store from '@/store'
// rest name, id, customer name, date & time, emp name, complain
export default {
  components: { VcChangeStatus, PrintComponent, AppCardActions, SelectBransh, DrawerPrint, ComplainDialog, ComplainBranchDialog },

  setup() {
    const orderTemp = ref({ Id: 0 })
    const isActive = ref(false)
    const isComplainBranchActive = ref(false)
    const complainTemp = ref({ Id: 0 })
    const isDialogDelete = ref(false)
    const dialog = ref(false)

    const {
      total,
      cancelCount,
      canceltotal,
      ComplainList,
      fetchOrders,
      OrderListTable,
      searchQuery,
      tableColumns,
      options,
      statusFilter,
      totalOrderListTable,
      loading,
      expanded,
      searchDateFrom,
      searchDateTo,
      restaurntFilter,
      branchFilter,
      orderSourceFilter,
      OrderTotalLocal,
      ResId,
      JustComplains,
      resolveTotalIcon,
    } = useOrderList()
      const dialogFitch = () => {
        dialog.value = false;
        fetchOrders()
        }
    return {

      JustComplains,
      ResId,
      total,
      cancelCount,
      canceltotal,
      dialogFitch,
      dialog,
      isComplainBranchActive,
      ComplainList,
      isActive,
      complainTemp,
      isDialogDelete,
      orderTemp,
      tableColumns,
      searchQuery,
      statusFilter,
      options,
      totalOrderListTable,
      OrderListTable,
      loading,
      expanded,
      fetchOrders,
      searchDateFrom,
      searchDateTo,
      restaurntFilter,
      branchFilter,
      orderSourceFilter,
      OrderTotalLocal,
      resolveTotalIcon,
      filter: { parseTime },
      icons: {
        mdiClockOutline,
        mdiCalendarRange,
        mdiDeleteOutline,
        mdiCommentFlash,
      },
    }
  },
}
</script>
